import { useState, useEffect } from "react";
import "./WordSwitcher.scss";

const words = ["blackbook.", "venues.", "racing tips."]; 

export default function WordSwitcher() {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimate(false); 
      setTimeout(() => {
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        setAnimate(true); 
      }, 500); 
    }, 2000); 

    return () => clearInterval(interval);
  }, []);

  return (
    <h2 className={`word-switcher ${animate ? "drop-in" : "drop-out"}`}>
      {words[currentWordIndex]}
    </h2>
  );
}
