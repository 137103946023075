import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import Header from '../../Layout/Header/Header';
import './Homepage.scss';
import WordSwitcher from "../../components/WordSwitcher/WordSwitcher";
import CustomerReview from '../../components/customerreview/customerreview';
import BoxTransforming from '../../components/BoxTransforming/BoxTransforming';
import Footer from '../../Layout/Footer/Footer';
import Bganimation from '../../components/bganimation/bganimation';

const Homepage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className="homepage">
      
      <Header />
      

      <div className="header-image">
        <img
          src="https://betdevelopers.s3.us-east-1.amazonaws.com/betdevelopers.svg"
          alt="Bet Developers Logo"
          className="logo-image"
        />
      </div>

      <main className="main-content">
        <div className="content">
          <h1 className="title">
            <span className="smart-data">Smart data,</span> <br />
            <span className="sharper-decisions">sharper decisions</span>
          </h1>
          <WordSwitcher />
          <h2 className="footer">
            Racing intelligence, data, and insights
            <span className="bottom-text">giving you the edge.</span>
          </h2>
        </div>
      </main>

      <div className="our-partners">
        <h2>Our Partners</h2>
        <div className="partner-logos">
  <a href="https://nabaffiliates.com/" target="_blank" rel="noopener noreferrer">
    <img
      src="https://betdevelopers.s3.us-east-1.amazonaws.com/nab+affiliates.svg"
      alt="NAB Affiliates"
      className="partner-logo"
    />
  </a>

  <a href="https://nabcasino.com/" target="_blank" rel="noopener noreferrer">
    <img
      src="https://betdevelopers.s3.us-east-1.amazonaws.com/nab.svg"
      alt="NAB"
      className="partner-logo"
    />
  </a>

  <a href="https://ptptips.com/" target="_blank" rel="noopener noreferrer">
    <img
      src="https://betdevelopers.s3.us-east-1.amazonaws.com/ptp.svg"
      alt="PTP"
      className="partner-logo"
    />
  </a>
</div>


        <div className="marketing-growth">
          Take Your Sports Betting<br />
          <span className='marketing'>to the</span> <span className='growth'>next level</span>
        </div>
      </div>
      
      <div className="features">
        <div className="delivers">
          BetDevelopers delivers the ultimate sports betting toolkit, featuring Blackbook, Horse Profiles,<br />
          <span className='jockeys'>Jockey Silks, Track Conditions, Venue Maps, and more—creating unbeatable player experiences.</span> 
        </div>
      </div>
      
      <div className="betting-tools">
        <Box className="betting-tool" sx={{ padding: 2, backgroundColor: 'transparent', color: '#fff', textAlign: 'center', borderRadius: '20px' }}>
          <img src="https://betdevelopers.s3.us-east-1.amazonaws.com/horse1+1.png" alt="Blackbook" className="tool-icon" />
          <h3>Blackbook</h3>
          <p>Track your favorites and get alerts for upcoming winning opportunities!</p>
        </Box>

        <Box className="betting-tool" sx={{ padding: 2, backgroundColor: 'transparent', color: '#fff', textAlign: 'center', borderRadius: '20px' }}>
          <img src="https://betdevelopers.s3.us-east-1.amazonaws.com/horse2.png" alt="Horse Profile" className="tool-icon" />
          <h3>Horse Profile</h3>
          <p>Access detailed stats and performance history for every horse!</p>
        </Box>

        <Box className="betting-tool" sx={{ padding: 2, backgroundColor: 'transparent', color: '#fff', textAlign: 'center', borderRadius: '20px' }}>
          <img src="https://betdevelopers.s3.us-east-1.amazonaws.com/venue.png" alt="Venue Maps" className="tool-icon" />
          <h3>Venue Maps</h3>
          <p>Explore race venues with interactive, detailed maps for easy navigation!</p>
        </Box>
      </div>

      <Box className="ask-pros-button">
  <button>
    ASK THE PROS
    <img src="https://betdevelopers.s3.us-east-1.amazonaws.com/arrow-right.png" alt="Arrow Right" />
  </button>
</Box>

<div className="winning-section">
  <h2><span style={{ color: '#19806C' }}>Winning</span> with BetDevelopers</h2>
  <h6>We excel in building lasting partnerships, offering top-tier horse racing and betting solutions.</h6>
  <div className="boxes-container">
    <div className="box box-1">
      <img src="https://betdevelopers.s3.us-east-1.amazonaws.com/horse3star.png" alt="Horse 3 Star" />
      <h3>Race Rater <span>Stars</span></h3>
      <p>Expert ratings for each race, highlighting top contenders and potential winners. Make smarter betting decisions with insights on form, jockeys, and conditions.</p>
    </div>
    <div className="box box-2">
    <h4>Horse Racing.<span>Tips & Ratings</span></h4>
    <p>Access expert ratings and in-depth tips to guide your betting strategy. With detailed analysis of horse performance, jockey stats, and track conditions, you'll have the insights needed to make smarter, more confident bets.</p>
      <img src="https://betdevelopers.s3.us-east-1.amazonaws.com/horse1star.png" alt="Horse 1 Star" />
    </div>
  </div>
  <div className="box box-3">
  <img src="https://betdevelopers.s3.us-east-1.amazonaws.com/speedmaps.png" alt="Speed Maps" />
  <div className="custom-heading">Speed Maps</div>
  <h1>Gain a competitive edge with Speed Maps, analyzing race pace, positioning, and track conditions. Identify front-runners, strong finishers, and race dynamics to make smarter betting decisions.</h1>
</div>

<CustomerReview />
<BoxTransforming />
<Footer />


</div>

    </Box>
  );
};

export default Homepage;
