import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import './Header.scss';

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
     
      {isMobile ? (
        <>
          {/* <IconButton className="burger-menu" onClick={toggleMenu}>
            <MenuIcon />
          </IconButton> */}
          {/* {isMenuOpen && (
            <nav className="nav-links-mobile">
              <Link to="/blank-page" onClick={toggleMenu}>ABOUT US</Link>
              <Link to="/blank-page" onClick={toggleMenu}>OUR BRANDS</Link>
              <Link to="/blank-page" onClick={toggleMenu}>PRODUCTS</Link>
              <Link to="/blank-page" onClick={toggleMenu}>GET IN TOUCH</Link>
            </nav>
          )} */}
        </>
      ) : (
        
        <nav className="nav-links">
          <Link to="/blank-page" style={{borderRight: "1px solid rgba(255, 255, 255, 0.25)" , borderRadius: "0px "}}>ABOUT US</Link>
          <Link to="/blank-page"style={{borderRight: "1px solid rgba(255, 255, 255, 0.25)" , borderRadius: "0px "}}>OUR BRANDS</Link>
          <Link to="/blank-page">PRODUCTS</Link>
          <Link to="/blank-page">GET IN TOUCH</Link>
        </nav>
      )}
    </header>
  );
};

export default Header;


// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { Box, IconButton } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import './Header.scss';

// const Header = () => {
//   const [isMobile, setIsMobile] = useState(false);
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768);
//     };

//     handleResize();
//     window.addEventListener('resize', handleResize);

//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   return (
//     <header className="header">
//       <Box className="logo">
//         <img
//           src="https://betdevelopers.s3.us-east-1.amazonaws.com/betdevelopers.svg"
//           alt="Bet Developers Logo"
//           className="logo-img"
//         />
//       </Box>
//       {/* {isMobile ? (
//         <>
//           <IconButton className="burger-menu" onClick={toggleMenu}>
//             <MenuIcon />
//           </IconButton>
//           {isMenuOpen && (
//             <nav className="nav-links-mobile">
//               <Link to="/blank-page" onClick={toggleMenu}>ABOUT US</Link>
//               <Link to="/blank-page" onClick={toggleMenu}>OUR BRANDS</Link>
//               <Link to="/blank-page" onClick={toggleMenu}>PRODUCTS</Link>
//               <Link to="/blank-page" onClick={toggleMenu}>SERVICES</Link>
//             </nav>
//           )}
//         </>
//       ) : (
//         <nav className="nav-links">
//           <Link to="/blank-page">ABOUT US</Link>
//           <Link to="/blank-page">OUR BRANDS</Link>
//           <Link to="/blank-page">PRODUCTS</Link>
//           <Link to="/blank-page">SERVICES</Link>
//         </nav>
//       )} */}
//     </header>
//   );
// };

// export default Header;