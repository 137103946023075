import React, { useEffect, useState, useRef } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import Homepage from './Pages/Homepage/Homepage'; 
import AboutUs from './Pages/AboutUs/Aboutus'; 
import BlankPage from './Pages/BlankPage';
import './App.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', 
    },
    secondary: {
      main: '#dc004e', 
    },
  },
  typography: {
    fontFamily: 'Comodo, Arial, sans-serif',
  },
});

const App = () => {
  const [useCustomCursor, setUseCustomCursor] = useState(false);
  const idleTimerRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = () => {
      setUseCustomCursor(true);
      clearTimeout(idleTimerRef.current);

      idleTimerRef.current = setTimeout(() => {
        setUseCustomCursor(false); 
      }, 500);
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      clearTimeout(idleTimerRef.current);
    };
  }, []);

  useEffect(() => {
    document.body.classList.toggle('custom-cursor', useCustomCursor);
  }, [useCustomCursor]);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/blank-page" element={<BlankPage />} /> 
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
