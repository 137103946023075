
const AboutUs = () => {
//   const navigate = useNavigate(); 

//   useEffect(() => {
    
//     const timer = setTimeout(() => {
//       navigate('/blank-page'); 
//     }, 2000); 

    
//     return () => clearTimeout(timer);
//   }, [navigate]);

//   return (
//     <div>
//       <h1>About Us</h1>
//       <p>This is the About Us page. You will be redirected shortly.</p>
//     </div>
//   );
};

export default AboutUs;