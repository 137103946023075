import { Box, Grid, Typography, Divider, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
// import { useContext } from "react";
// import { StateContext } from "src/context/StateContext";

const Footer = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  if (isMobile) return null; // Hide footer on mobile devices

  return (
    <Box sx={{
      backgroundColor: "#transparent",
      color: "white",
      padding: "3rem 2rem",
      textAlign: "center",
      fontFamily: "Poppins, sans-serif",
    }}>

      {/* Footer Links and Sections */}
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={6} md={3}>
          <Typography 
            variant="h0" 
            sx={{ fontFamily: "Poppins, sans-serif", position: "relative", left: "-27%" }}
          >
            Customized deal
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "left", marginLeft: "10%" }}>
            <Link 
              to="/about" 
              style={{ fontFamily: "Poppins, sans-serif", color: "#b4bec6", textDecoration: "none", marginTop: "8px", fontSize: "13px" }}
            >
              Ratings & Form
            </Link>
            <Link 
              to="/careers" 
              style={{ fontFamily: "Poppins, sans-serif", color: "#b4bec6", textDecoration: "none", marginTop: "8px", fontSize: "13px" }}
            >
              Home
            </Link>
            <Link 
              to="/contact" 
              style={{ fontFamily: "Poppins, sans-serif", color: "#b4bec6", textDecoration: "none", marginTop: "8px", fontSize: "13px" }}
            >
              Ratings & Form
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography 
            variant="h0" 
            sx={{ fontFamily: "Poppins, sans-serif", position: "relative", left: "-27%" }}
          >
            Products
          </Typography>
          <Box 
            sx={{ 
              display: "flex", 
              flexDirection: "column", 
              alignItems: "flex-start", 
              textAlign: "left", 
              marginLeft: "15.5%"
            }}
          >
            <Link to="/products" style={{ fontFamily: "Poppins, sans-serif", color: "#b4bec6", textDecoration: "none", marginTop: "8px", fontSize: "13px" }}>
              Blackbook
            </Link>
            <Link to="/solutions" style={{ fontFamily: "Poppins, sans-serif", color: "#b4bec6", textDecoration: "none", marginTop: "8px", fontSize: "13px" }}>
              Horse Profile
            </Link>
            <Link to="/solutions" style={{ fontFamily: "Poppins, sans-serif", color: "#b4bec6", textDecoration: "none", marginTop: "8px", fontSize: "13px" }}>
              Venue Maps
            </Link>
          </Box>
        </Grid>

        {/* Second Column */}
        <Grid item xs={12} sm={6} md={3}>
          <Typography 
            variant="h0" 
            sx={{ fontFamily: "Poppins, sans-serif", color: "#fff", position: "relative", left: "-37%" }}
          >
            Horse Racing
          </Typography>
          <Box 
            sx={{ 
              display: "flex", 
              flexDirection: "column", 
              alignItems: "flex-start", 
              textAlign: "left", 
              marginLeft: "2%"
            }}
          >
            <Link to="/faq" style={{ fontFamily: "Poppins, sans-serif", color: "#b4bec6", textDecoration: "none", marginTop: "8px", fontSize: "13px" }}>
              Tips & Ratings
            </Link>
            <Link to="/policy" style={{ fontFamily: "Poppins, sans-serif", color: "#b4bec6", textDecoration: "none", marginTop: "8px", fontSize: "13px" }}>
              Race Rater Stars
            </Link>
            <Link to="/terms" style={{ fontFamily: "Poppins, sans-serif", color: "#b4bec6", textDecoration: "none", marginTop: "8px", fontSize: "13px" }}>
              Speed Maps
            </Link>
          </Box>
        </Grid>
      </Grid>

      {/* Bottom Divider */}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Divider sx={{ width: "70%", backgroundColor: "rgba(158, 158, 158, 0.35)", margin: "2rem 0" }} />
      </Box>

      {/* Copyright & Branding */}
      <Grid container justifyContent="space-between" alignItems="center">
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "16px", position: "relative", marginLeft: "15%" }}>
          <img 
            src="https://betdevelopers.s3.us-east-1.amazonaws.com/footerlogo.png" 
            alt="Footer Logo"
            style={{ width: "130px", height: "auto" }}
          />
        </Box>

        <Grid item xs={12} sm={4} sx={{ fontFamily: "Poppins, sans-serif", textAlign: "center" }}>
          <Typography variant="body2" sx={{ fontFamily: "Poppins, sans-serif", color: "#b4bec6", position: "relative", left: "-40%", transform: "translateY(230%)" }}>
            &copy; {new Date().getFullYear()} BetDevelopers. All Rights Reserved. Privacy Policy
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ textAlign: "right" }}>
          <Typography></Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} sx={{ textAlign: "right" }}>
            <Typography></Typography>
            
            {/* Wrap images in a div with a white background */}
            <div style={{ display: "inline-block", backgroundColor: "white", position: "relative", left: "120%", padding: "3px" }}>
              <img 
                src="https://betdevelopers.s3.us-east-1.amazonaws.com/insta.png" 
                alt="Instagram" 
                style={{ width: "100%" , height: "auto" }}
              />
            </div>
            
            <div style={{ display: "inline-block", backgroundColor: "white", position: "relative", left: "122.5%", padding: "3px" }}>
              <img 
                src="https://betdevelopers.s3.us-east-1.amazonaws.com/x.png" 
                alt="X" 
                style={{ width: "100%", height: "auto" }}
              />
            </div>
            
            <div style={{ display: "inline-block", backgroundColor: "white" , position: "relative", left: "125%", padding: "3px" }}>
              <img 
                src="https://betdevelopers.s3.us-east-1.amazonaws.com/facebook.png" 
                alt="Facebook" 
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
