import { Box, Typography } from "@mui/material";
import "./BoxTransforming.scss";

const BoxTransforming = () => {
  return (
    <Box className="box-transforming-container">
      <Typography variant="h3" className="box-transforming-title">
        Unsure Which products fit your needs?
      </Typography>

      <Typography variant="body1" className="box-transforming-subtitle">
        Reach out to BetDevelopers management team to explore our products or schedule a demo.
        <span>We're here to help accelerate your growth in the sports betting market.</span>
      </Typography>

      <Box className="pros-button">
        <button>
          ASK THE PROS
          <img src="https://betdevelopers.s3.us-east-1.amazonaws.com/arrow-right.png" alt="Arrow Right" />
        </button>
      </Box>
    </Box>
  );
};

export default BoxTransforming;