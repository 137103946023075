import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import styles from "./customerreview.scss";


const testimonials = [
  {
    name: "PTPTIPS",
    review:
      "BetDevelopers has revolutionized our horse racing operations. It boosts margins, automates processes, and offers tailored solutions that create new opportunities—truly a game-changer for our business.",
    avatar: "https://betdevelopers.s3.us-east-1.amazonaws.com/ptplogo.png",
  },
  {
    name: "NAB CASINO",
    review:
      "Compared to other providers, Betdevelopers has improved our online casino. It increases our profits, automates tedious tasks, and offers more options. It's truly made a huge difference.",
    avatar: "https://betdevelopers.s3.us-east-1.amazonaws.com/nablogo.png",
  },
];

const CustomerReview = () => {
  const [index, setIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 100) {
          setFade(true);
          setIndex((prev) => (prev + 1) % testimonials.length);
          setProgress(0);

          setTimeout(() => {
            setFade(false);
          }, 500);

          return 100;
        }
        return oldProgress + 10;
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "transparent",
        padding: { xs: "5rem 2rem", sm: "13rem 15rem" }, // responsive padding
        textAlign: "center",
        color: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // center alignment for mobile
        fontFamily: "Poppins, sans-serif",
      }}
    >
     <Typography
  variant="h3"
  sx={{
    fontWeight: "normal",
    textTransform: "capitalize",
    fontFamily: "Poppins, sans-serif",
    marginBottom: "1rem",
    width: { xs: "100%", sm: "30%" },
    fontSize: { xs: "36px", sm: "48px" },
    marginLeft: { xs: "2%", sm: "-79%" }, // Moves 30% right on mobile
  }}
>
  Customer <span style={{ color: "#21c07a" }}>Reviews</span> & Testimonials
</Typography>

<Typography
  variant="body1"
  sx={{
    marginTop: 2,
    marginLeft: { xs: "-1%", sm: "-61%" }, // Moves 30% right on mobile
    opacity: 0.9,
    maxWidth: "500px",
    fontFamily: "Poppins, sans-serif",
    marginBottom: "3rem",
  }}
>
  We regularly engage with our partners to understand how we can offer the
  most effective products and services for their operations. Check out our
  latest testimonials.
</Typography>

<Box
  sx={{
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: { xs: "0", sm: "60%" }, // No left margin on mobile
    marginTop: { xs: "15%", sm: "-31%" },
    height: { xs: "220px", sm: "340px" }, // Smaller height on mobile
    flexDirection: "column", // Stacked on mobile
  }}
>
  {[...Array(2)].map((_, i) => (
    <Box
      key={i}
      sx={{
        position: "absolute",
        width: { xs: "350px", sm: "550px" }, // Smaller width on mobile
        height: { xs: "270px", sm: "340px" }, // Smaller height on mobile
        border: "1px solid rgba(158, 158, 158, 0.35)",
        backgroundColor: i === 1 ? "#030A09" : "#135d4a",
        borderRadius: { xs: "1rem", sm: "2rem" }, // Smaller border radius on mobile
        transform: `rotate(${i * 11}deg)`,
        boxShadow: "0px 6px 25px rgba(0, 0, 0, 0.5)",
        zIndex: i,
      }}
    />
  ))}


        <Card
          sx={{
            backgroundColor: "#112825",
            color: "white",
            padding: "1rem",
            maxWidth: "500px",
            minHeight: "330px",
            borderRadius: "2rem",
            border: "1px solid rgba(158, 158, 158, 0.35)",
            boxShadow: "0px 6px 25px rgba(0, 0, 0, 0.5)",
            textAlign: "left",
            position: "relative",
            zIndex: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%", // full width on mobile
          }}
        >
          <CardContent>
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}>
              {[...Array(5)].map((_, i) => (
                <StarIcon key={i} sx={{ color: "#FFD700" }} />
              ))}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                marginBottom: 2,
              }}
            >
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={testimonials[index].avatar}
                  alt="logo"
                  style={{ width: "100%", height: "100%" }}
                />
              </Box>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  fontFamily: "Poppins, sans-serif",
                  opacity: fade ? 0 : 1,
                  transition: "opacity 0.5s ease-in-out",
                  fontSize: { xs: "20px", sm: "24px" }, // responsive font size
                }}
              >
                {testimonials[index].name}
              </Typography>
            </Box>

            <Typography
              variant="body1"
              sx={{
                opacity: fade ? 0 : 1,
                lineHeight: "1.6",
                fontFamily: "Poppins, sans-serif",
                transition: "opacity 0.5s ease-in-out",
                fontSize: { xs: "14px", sm: "16px" }, // responsive font size
              }}
            >
              {testimonials[index].review}
            </Typography>
          </CardContent>

          <Box
            sx={{
              height: "4px",
              backgroundColor: "rgba(255, 255, 255, 0.3)",
              borderRadius: "2px",
              overflow: "hidden",
              marginTop: "auto",
              width: "90%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Box
              sx={{
                width: `${progress}%`,
                height: "100%",
                backgroundColor: "white",
                transition: "width 0.5s ease-in-out",
              }}
            />
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default CustomerReview;
