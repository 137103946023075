
import React from 'react';
import { Link } from 'react-router-dom';

const BlankPage = () => {
  return (
    <div style={{ backgroundColor: 'white', height: '100vh' }}>
      <nav className='back-home'> 
        <Link to="/">ABOUT US</Link>
        
        <p>This is a blank page.</p>
      </nav>   
    </div>
  );
};

export default BlankPage;
